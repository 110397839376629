@import "../../scss/colours";

.loader {
  background-color: $green;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .mark {
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
      display: none;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: $dark-grey;
    }

    .mark-head {

      &-enter {
        svg {
          height: 0;
          width: 0;
        }
      }

      &-enter-active {
        svg {
          height: 41px;
          width: 40px;
          transform: rotate(360deg);
          transition: all linear 2s;
        }
      }

      &-enter-done {
        svg {
          animation: spin 2s linear infinite;
        }
      }

      &-exit {
        svg {
          display: none !important;
        }

        .circle {
          display: block;
        }
      }

      &-exit-active {

        svg {
          display: none !important;
        }

        .circle {
          display: block;
          transform: scale(100);
          transition: 2s transform linear;
        }
      }

      &-exit-done {

        svg {
          display: none !important;
        }

        .circle {
          display: block;
          transform: scale(100);
        }
      }
    }

    .mark-body {
      width: 122px;
      height: 91px;

      &-enter {
        width: 0;
        height: 0;
      }

      &-enter-active {
        width: 122px;
        height: 91px;
        transition: all linear 2s;
      }

      &-enter-done {
      }
    }

  }

  .loader-title {
    text-align: center;
    font-size: 18px;
    color: $dark-grey;
    margin-top: 30px;
    height: 20px;
  }
}

.left-slide-enter {
  transform: translateX(-200px) skewX(30deg);
  opacity: 0;
}

.left-slide-enter-active {
  opacity: 1;
  transform: translateX(0) skewX(0deg);
  transition: all ease-in 1500ms;
}

.left-slide-enter-done {
   //display: block;
}

.left-slide-exit {

}
.left-slide-exit-active {
  opacity: 0;
  transform: translateX(200px) skewX(-30deg);
  transition: all ease-in 1500ms;
}
.left-slide-exit-done {
  opacity: 0;
}





@keyframes start-title {
  0% {
    opacity: 0;
    transform: translateX(-200px) skewX(30deg);
  }

  30% {
    opacity: 1;
    transform: translateX(0) skewX(0deg);
  }

  60% {
    opacity: 0;
    transform: translateX(200px) skewX(-30deg);
  }

  90% {
    opacity: 0;
    transform: translateX(200px) skewX(-30deg);
    display: none;
  }

  100% {
    //opacity: 1;
    //transform: translateX(0);
  }
}

@keyframes fade-in-horizontal {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

