@import "./src/scss/colours";
@import "./src/scss/breakpoints";

.language-switch {
  background-color: #FFFFFF;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  margin-right: 20px;

  span {
    font-size: 18px;
    color: #1E1B18;
    font-family: "Mark Simpson Proxima Nova", Tahoma, Arial, sans-serif;
  }
}

.language-switch-modal {
  background-color: $white;
  width: 100%;
  height: 100%;
  padding: 50px;
  color: $dark-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-title {
    text-transform: uppercase;
    font-size: 50px;
    line-height: 50px;
    font-family: "Mark Simpson Proxima Nova", Arial, Helvetica, sans-serif;
  }

  &-close-btn {
    line-height: 0;
    border: 1px solid $dark-grey;
    height: 45px;
    width: 45px;
    min-width: 45px;
    border-radius: 24.5px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      height: 19px;
      width: 19px;

      g {
        path {
          fill: $dark-grey;
        }
      }
    }
  }

  &-items {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .language-switch-modal-item {
      text-decoration: none;
      font-size: 20px;
      text-transform: lowercase;
      font-family: "Mark Simpson Proxima Nova", sans-serif;
      font-weight: bold;
      color: $dark-grey;
      height: 60px;
      border-radius: 30px;
      border: $dark-grey 1px solid;
      min-width: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      &.active,
      &:hover{
        color: $white;
        background-color: $dark-grey;
        transition: all ease-in 250ms;
      }

      margin: 0 10px 20px 10px;

      //&  + .language-switch-modal-item {
      //  margin-left: 20px;
      //}
    }
  }

  &-subtitle {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.language-switch-exit-active {
  min-width: 0;
  position: relative;
  transform: scale(100);
  transition: all ease-in 1s;
  z-index: 9999;
}

@media screen and (max-width: $big) {
  .language-switch {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 15px;
  }
}

@media screen and (max-width: $small) {
  .language-switch {
    height: 34px;
    width: 34px;
    border-radius: 17px;
    margin-right: 10px;


    span {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .language-switch-modal {
    padding: 25px;
    color: $dark-grey;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-title {
      font-size: 30px;
      line-height: 30px;
    }

    &-close-btn {
      height: 35px;
      width: 35px;
      min-width: 35px;
      border-radius: 17.5px;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &-items {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-flow: nowrap column;

      .language-switch-modal-item {
        font-size: 20px;
        text-transform: lowercase;
        height: 50px;
        border-radius: 25px;
        min-width: 200px;

        &.active,
        &:hover{
          color: $white;
          background-color: $dark-grey;
          transition: all ease-in 250ms;
        }


        &  + .language-switch-modal-item {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }

    &-subtitle {
      padding: 0 45px;
      text-align: center;
      font-size: 16px;
    }
  }
}