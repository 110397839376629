@import "src/scss/colours";
@import "src/scss/breakpoints";
@import "src/scss/Components/Views/Results/GenerateCard/generateCard";

.results {
  background-color: $dark-grey;
  position: relative;
  color: $white;
  overflow-y: hidden;
  height: 100vh;

  .results-header {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    padding: 50px 50px 0 50px;
    background: transparent;
    justify-content: space-between;

    .insaider-mark {
      width: 40px;
      height: 45px;
    }

    .analytics {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin:  0 auto 0 15px;

      .analytics-count {
        font-size: 20px;
        line-height: 24px;
      }

      .analytics-message {
        font-size: 12px;
        line-height: 14px;
        max-width: 65px;
        text-decoration: underline;
      }
    }

    .result-filter-btn {
      min-width: 170px;
      height: 50px;
      border: 1px solid $green;
      border-radius: 25px;
      background: transparent;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      cursor: pointer;

      &.selected {
        background-color: $green;
        color: $dark-grey;

        .filter-star {


          g {
            path {
              fill: $dark-grey;
            }
          }
      }

      }

      .title-wrapper {
        display: block;
      }

      .btn-title {
        font-size: 17px;
        line-height: 17px;
      }

      .filter-star {
        width: 18px;
        height: 17px;
        margin-left: 10px;

        g {
          path {
            fill: $green;
          }
        }
      }

      &-enter {
        min-width: unset;
        width: 150px;
      }

      &-enter-active {
        padding: 0 !important;
        width: 50px;
        min-width: 0;
        background-color: $green;
        transition: all ease 500ms;
      }

      &-enter-done {
        padding: 0 !important;
        width: 50px;
        min-width: 0;
        background-color: $green;
      }

      &-exit {
        min-width: 0;
        height: 50px;
        width: 50px;
        background-color: $green;
        border-radius: 25px;
        padding: 0 !important;
        z-index: 9999;
      }

      &-exit-active {
        min-width: 0;
        position: relative;
        transform: scale(100);
        transition: all ease-in 2000ms;
        z-index: 9999;
      }

      &-exit-done {
        min-width: 0;
        z-index: 9999;
        background-color: $green;
        box-shadow: 0 0 0 200vw #2DD881;
        position: relative;
      }
    }
  }

  .results-content {
    width: 600px;
    margin: 0 auto;
    position: relative;


    .result-card,
    .generate-card {
      margin-bottom: 20px;
    }

    .generate-card {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .results-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 50px 50px 50px;
    margin-top: 40px;

    .smsq-logo {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: $big) {
  .results {
    .results-header {
      padding: 40px 40px 0 40px;

      .insaider-mark {
        width: 36px;
        height: 40px;
      }

      .result-filter-link {
        padding: 0 25px;
        margin-left: auto;

        .filter-star {
          width: 22px;
          height: 22px;
        }
      }

      .result-filter-btn {
        &-enter {
          min-width: unset;
        }

        &-enter-active {
          min-width: 0;
        }

        &-enter-done {
          min-width: 0;
        }

        &-exit {
          min-width: 0;
        }

        &-exit-active {
          min-width: 0;
        }

        &-exit-done {
          min-width: 0;
        }
      }
    }

    .results-content {
      width: fit-content;

      .swiper-container {
        margin-top: -520px;
      }
    }

    .results-footer {
      padding: 0 40px 40px 40px;
    }
  }
}

@media screen and (max-width: $medium) {
  .results {

    .results-header {
      .result-filter-btn {
        min-width: 135px;
        height: 40px;
        border-radius: 20px;
        padding: 0 15px;

        span {
          font-size: 14px;
          line-height: 18px;
        }

        &-enter {
          min-width: unset;
        }

        &-enter-active {
          min-width: 0;
        }

        &-enter-done {
          min-width: 0;
        }

        &-exit {
          min-width: 0;
        }

        &-exit-active {
          min-width: 0;
        }

        &-exit-done {
          min-width: 0;
        }

      }
    }

    .results-content {
      width: 100%;
      display: flex;
      justify-content: center;

      .slider-container {
        margin: 0 auto;
        //width: fit-content;
      }
    }

    .results-footer {
      position: static;
      width: 100%;
    }


    .navs-arrows {
      display: none;
    }
  }
}

@media screen and (max-width: $small) {
  .results {
    min-width: 100%;

    .results-header {
      padding: 30px 30px 0 30px;

      .insaider-mark {
        width: 31px;
        height: 35px;
      }

      .result-filter-btn {
        min-width: 135px;
        height: 40px;
        border-radius: 20px;
        padding: 0 15px;

        span {
          font-size: 14px;
          line-height: 18px;
        }

        &-enter {
          min-width: unset;
        }

        &-enter-active {
          min-width: 0;
        }

        &-enter-done {
          min-width: 0;
        }

        &-exit {
          min-width: 0;
        }

        &-exit-active {
          min-width: 0;
        }

        &-exit-done {
          min-width: 0;
        }
      }

      .analytics {
        margin-left: 10px;

        .analytics-count {
          font-size: 14px;
          line-height: 18px;
        }

        .analytics-message {
          font-size: 9px;
          line-height: 11px;
          max-width: 45px;
          text-decoration: underline;
        }
      }

      .result-filters-list {
        flex-wrap: wrap;

        .result-filter,
        .result-filter-link {
          height: 34px;
          border-radius: 17px;
        }

        .result-filter-link {
          width: 34px;

          .filter-star {
            width: 19.44px;
            height: 18.57px;
          }
        }
      }
    }

    .results-content {
      width: 100%;

      .slider-container {
        width: 100%;
      }
    }

    .results-footer {
      padding: 0 30px 30px 30px;
    }
  }
}

