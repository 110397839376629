@import '../../../../../scss/breakpoints';

.card-container {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  margin-bottom: 0;
  width: calc(50% - 45px);


  svg {
    min-width: 22px;
    margin-bottom: 17px;
  }

  .card-text {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: $big) {

  .card-container {
    width: calc(50% - 30px);

    .card-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $medium) {

  .card-container {
    width: calc(50% - 20px);
    margin-bottom: 30px;

    svg {
      margin-bottom: 15px;
      min-width: 27px;
    }

    .card-text {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: $small) {

  .card-container {
    width: calc(50% - 15px);
  }
}

