@import './src/scss/colours';
@import './src/scss/breakpoints';

.check-default {
  height: 41px;
  width: 41px;

  g {
    path {
      fill: #2DD881;
    }
  }
}