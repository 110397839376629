@import "src/scss/breakpoints";
@import "src/scss/colours";

.rating-modal {

  &-container {
    width: 100%;
    height: 100%;
    background-color: $blue;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px;
    color: $white;
    overflow-y: auto;
    position: relative;
  }

  &-header {
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
  }

  &-footer {
    margin-top: auto;
    display: flex;
    justify-content: center;

    .casino-info {
      display: flex;
      align-items: center;

      &-name {
        margin-left: 20px;
        text-transform: uppercase;
        font-family: 'Mark Simpson Proxima Nova', Tahoma, sans-serif;
      }

      figure {
        height: 70px;
        width: 70px;
        border-radius: 35px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  &-subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-title {
    text-transform: uppercase;
    font-size: 50px;
    line-height: 50px;
    font-weight: 900;
    font-family: "Mark Simpson Proxima Nova", Arial, Helvetica, sans-serif;
  }

  &-content-wrapper {
    margin-bottom: 40px;
  }


  &-close-btn {
    line-height: 0;
    border: 1px solid $white;
    height: 45px;
    width: 45px;
    min-width: 45px;
    border-radius: 24.5px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      height: 19px;
      width: 19px;

      g {
        path {
          fill: $white;
        }
      }
    }
  }
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.rating-item {
  border: dashed 1px $white;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 7.5px 0 20px;
  border-radius: 22.5px;
  text-transform: lowercase;
  cursor: pointer;
  margin-bottom: 10px;

  .rating-item-label {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-left: 25px;
  }

  &:hover,
  &.active {
    color: $dark-grey;
    transition: all ease-in 200ms;
  }

  &.green {
    &:hover,
    &.active {
      background-color: $green;
      border: 1px $green solid;
    }

    .rating-item-label {
      background-color: $green;
    }
  }

  &.yellow {
    &:hover,
    &.active {
      background-color: $yellow;
      border: 1px $yellow solid;

    }

    .rating-item-label {
      background-color: $yellow;
    }
  }

  &.pink {
    &:hover,
    &.active {
      background-color: $pink;
      border: 1px $pink solid;
    }

    .rating-item-label {
      background-color: $pink;
    }
  }

  & + .rating-item {
    margin-left: 15px;
  }
}

.casino-rating-link {
  min-width: 300px;
  margin: 0;
  background-color: $white;
  color: $dark-grey;
  text-decoration: none;
  height: 70px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 700;
  font-family: "Mark Simpson Proxima Nova", Tahoma, Arial, sans-serif;

  &:hover {
    background-color: $dark-grey;
    color: $white;
    transition: all 250ms ease-in;
  }
}

.casino-rating {
  height: 70px;
  border-radius: 35px;
  border: 4px solid $white;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto;

  &.casino-rating-top {
    display: none;
  }

  .casino-rating-score {
    margin-left: 40px;
  }

  .casino-rating-title {
    margin-left: 15px;
  }

  &-dice {
    height: 30px;
    width: 30px;

    g {
      path {
        fill: $white !important;
      }
    }
  }
}

.item-sidebar {

  &-container {
    color: $dark-grey;
    background-color: $white;
    border-radius: 6px;

    position: absolute;
    top: 30px;
    left: 30px;
    bottom: 30px;
    width: 450px;
    padding: 40px;
    overflow-y: auto;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &-title {
    font-family: "Mark Simpson Proxima Nova", Tahoma, Arial, sans-serif;

    h3 {
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  &-close-btn {
    line-height: 0;
    border: 1px solid $dark-grey;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 20px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;

      g {
        path {
          fill: $dark-grey;
        }
      }
    }
  }

  &-name {
    font-weight: bold;
    text-transform: lowercase;
    font-size: 18px;
    margin-bottom: 40px;
  }

  &-values {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .item-sidebar-value {
      margin: 0;
      border: 1px dashed $very-light-gray;
      width: 100%;

      & + .item-sidebar-value {
        margin-top: 10px;
      }
    }
  }

  &-description {
    font-size: 14px;

    ul {
      list-style-type: none;
    }
  }

}

.casino-rating-disclaimer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  margin-top: 20px;
  flex-wrap: wrap;

  a {
    color: $white;
  }
}


@media screen and (max-width: $big) {
  .rating-modal {
    &-container {
      padding: 40px;
    }

    &-footer {
      .casino-info {

        figure {
          height: 60px;
          width: 60px;
          border-radius: 30px;
        }
      }
    }

    &-title {
      font-size: 40px;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 20px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &-subtitle {
      font-size: 16px;
    }

  }

  .rating-item {
    height: 40px;
    border-radius: 20px;

    .rating-item-label {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      margin-left: 30px;
    }
  }

  .casino-rating {
    height: 60px;
    border-radius: 30px;
    font-size: 16px;

    .casino-rating-score {
      margin-left: 30px;
    }

    .casino-rating-title {
      margin-left: 10px;
    }

    &-dice {
      height: 25px;
      width: 25px;
    }
  }

  .casino-rating-link {
    min-width: 260px;
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
  }

  .item-sidebar {

    &-container {
      width: 350px;
      padding: 30px;
    }

    &-header {
      margin-bottom: 20px;
    }

    &-title {
      h3 {
        font-size: 28px;
        line-height: 28px;
      }
    }

    &-name {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: $medium) {
  .rating-modal {

    &-container {
      padding: 30px;
    }

    &-footer {
      flex-wrap: wrap;

      .casino-info {
        margin-bottom: 20px;

        &-name {
          font-size: 16px;
        }

        figure {
          height: 55px;
          width: 55px;
          border-radius: 27.5px;
        }
      }
    }

    .casino-rating,
    .casino-rating-link {
      height: 55px;
      border-radius: 27.5px;
      margin-bottom: 20px;
    }

    .casino-rating-link {
      min-width: 240px;
      font-size: 20px;
    }
  }

  .rating-item {
    height: 35px;
    border-radius: 17.5px;
    font-size: 11px;

    .rating-item-label {
      width: 25px;
      height: 25px;
      border-radius: 12.5px;
    }
  }

  .casino-rating,
  .casino-rating-link {
    height: 55px;
    border-radius: 27.5px;
    margin-bottom: 20px;
  }

  .casino-rating-link {
    min-width: 240px;
    font-size: 20px;
  }

  .item-sidebar {

    &-header {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: $small) {
  .rating-modal {

    &-container {
      padding: 25px;
    }

    &-title {
      font-size: 30px;
      line-height: 30px;
      max-width: calc(100% - 35px);

      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    &-close-btn {
      height: 35px;
      width: 35px;
      min-width: 35px;
      border-radius: 17.5px;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &-subtitle {
      font-size: 14px;
    }

    &-footer {
      flex-wrap: wrap;

      .casino-info {
        width: 100%;
        justify-content: center;

        &-name {
          font-size: 14px;
        }

        figure {
          height: 50px;
          width: 50px;
          border-radius: 25px;
        }
      }
    }
  }

  .casino-rating,
  .casino-rating-link {
    height: 50px;
    min-height: 50px;
    border-radius: 25px;
  }

  .casino-rating {
    margin-bottom: 20px;
    font-size: 14px;
    width: 100%;

    &.casino-rating-top {
      display: flex;
    }
  }


  .casino-rating-link {
    min-width: 200px;
    font-size: 16px;
    margin: 0 auto;
  }

  .item-sidebar {

    &-container {
      position: fixed;
      top: 20px;
      left: 20px;
      bottom: 20px;
      width: 300px;
      min-width: 275px;
      padding: 20px;
    }

    &-header {
      margin-bottom: 20px;
    }

    &-title {

      h3 {
        font-size: 24px;
        line-height: 24px;
      }
    }

    &-close-btn {

      height: 35px;
      width: 35px;
      min-width: 35px;
      border-radius: 17.5px;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &-name {
      font-size: 14px;
      margin-bottom: 30px;
    }

    &-values {
      margin-bottom: 30px;

      .item-sidebar-value {
        min-height: 35px;
        height: unset;

        .rating-item-label {
          height: 25px;
          width: 25px;
          border-radius: 12.5px;
        }
      }
    }

    &-description {
      font-size: 13px;
    }
  }

  .casino-rating-disclaimer {
    justify-content: center;

    .casino-rating-disclaimer-note,
    .casino-rating-disclaimer-links {
      min-width: 100%;
    }
  }
}
