@import './src/scss/colours';
@import './src/scss/breakpoints';

.arrow-default {
  width: 18px;
  height: 100px;

  g {
    path {
      fill: $white;
    }
  }

  &.arrow-revers {
    transform: rotate(180deg);
  }
}

