@import '../../../../scss/breakpoints';

.cards-container {
  grid-area: cards;
  display: flex;
  box-sizing: border-box;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 90px;
  background: rgba(84, 101, 255, .07);

  .cards-row {
    display: flex;
    width: 100%;

    justify-content: space-between;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $big) {
  .cards-container {
    padding: 60px;
  }
}

@media screen and (max-width: $medium) {
  .cards-container {
    flex-flow: wrap row;
    padding: 50px 50px 20px 50px;

    .cards-row {
      width: calc(50% - 20px);
    }
  }
}

@media screen and (max-width: $small) {
  .cards-container {
    flex-flow: column nowrap;
    padding: 30px;

    .cards-row {
      margin-bottom: 30px;
      justify-content: space-between;
      min-width: unset;
      max-width: unset;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}