@import "src/scss/breakpoints";

.basic-container {
  grid-area: basic;
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background: rgba(84, 101, 255, .07);


  &_horizontal {
    flex-direction: row;
  }

  &_vertical {
    flex-direction: column;
  }

  .basic-content {

    display: flex;
    height: 100%;

    .basic-description,
    .basic-logo {
      text-align: center;
    }

    .basic-logo {
      margin-bottom: 200px;

      .logo-svg {
        g {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }

    .basic-description {

      &_margin {
        margin-bottom: 220px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .main-text {
        font-size: 24px;
        line-height: 34px;
        margin: 0 0 40px 0;
        padding: 0 80px;

        &_horizontal {
          padding: 0px 185px;
        }

      }

      .sub-text {
        font-size: 16px;
        line-height: 24px;
        padding: 0 95px;
        margin: 40px 0 0 0;

        &_horizontal {
          padding: 0 190px;
        }
      }
    }
  }
}

.basic-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .smsq-logo {
    margin-left: auto;
  }

  .language {
    margin-right: 30px;
  }
}

@media screen and (max-width: $big) {
  .basic-container {
    padding: 30px;

    .basic-content {
      .basic-logo {
        margin-bottom: 130px;
      }

      .basic-description {

        &_margin {
          margin-bottom: 155px;
        }

        .main-text {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 35px;
          padding: 0 30px;

          &_horizontal {
            padding: 0 160px;
          }
        }

        .sub-text {
          font-size: 14px;
          line-height: 20px;
          padding: 0 30px;
          margin: 35px 0 0 0;

          &_horizontal {
            padding: 0 190px;
          }
        }
      }
    }
  }

  .basic-footer {

    .language {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: $medium) {
  .basic-container {

    &_horizontal {
      flex-direction: column;
    }

    .basic-content {
      .basic-logo {
      }

      .basic-description {
        margin-bottom: 155px;

        .main-text {

          &_horizontal {
            padding: 0 30px;
          }
        }

        .sub-text {

          &_horizontal {
            padding: 0 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small) {
  .basic-container {
    padding: 20px;

    &_horizontal {
      flex-direction: column;
    }

    .basic-content {
      .basic-logo {
        margin: 35px 0 80px 0;

      }

      .basic-description {
        margin-bottom: 90px;

        .main-text {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 30px;
          padding: 0 20px;

          .link {
            font-size: 16px;
            line-height: 20px;
          }

          &_horizontal {
            padding: 0 20px;
          }
        }

        .sub-text {
          font-size: 12px;
          line-height: 16px;
          padding: 0 10px;
          margin: 30px 0 0 0;

          &_horizontal {
            padding: 0 10px;
          }
        }
      }
    }
  }
}