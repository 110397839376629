@import "../../../scss/breakpoints";

.home-container {
  display: grid;
  background: #1E1B18;
  grid-template-columns: 675px 675px;
  grid-template-rows: 467px 467px;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
  padding: 30px 0;

  &_first {
    grid-template-areas:
  "basic winning"
  "basic cards";
  }

  &_second {
    grid-template-areas:
    "basic basic"
    "winning cards";
  }

  &_third {
    grid-template-areas:
     "winning basic"
     "cards basic";
  }

  &_fourth {
    grid-template-areas:
      "winning cards"
      "basic basic";
  }
}

@media screen and (max-width: $big) {
  .home-container {
    grid-template-columns: 595px 595px;
    grid-template-rows: 355px 355px;
  }
}

@media screen and (max-width: $medium) {
  .home-container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0;
    row-gap: 0;

    .basic-container,
    .winning-container,
    .cards-container {
      max-width: calc(100% - 40px);
      width: calc(100% - 40px);
    }

    &_first {
      .basic-container {
        order: 1;
        margin-bottom: 20px;
      }

      .winning-container {
        order: 2;
        margin-bottom: 20px;
      }

      .cards-container {
        order: 3;
        margin-bottom: 0;
      }
    }

    &_second {
      .basic-container {
        order: 2;
        margin-bottom: 20px;
      }

      .winning-container {
        order: 1;
        margin-bottom: 20px;
      }

      .cards-container {
        order: 3;
        margin-bottom: 0;
      }
    }

    &_third {
      .basic-container {
        order: 3;
        margin-bottom: 0;
      }

      .winning-container {
        order: 1;
        margin-bottom: 20px;
      }

      .cards-container {
        order: 2;
        margin-bottom: 20px;
      }
    }

    &_fourth {
      .basic-container {
        order: 2;
        margin-bottom: 20px;
      }

      .winning-container {
        order: 3;
        margin-bottom: 0;
      }

      .cards-container {
        order: 1;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: $small) {
  .home-container {
    padding: 10px;

    .basic-container,
    .winning-container,
    .cards-container {
      max-width: 100%;
      width: 100%;
    }
  }
}

