@import "./colours";

.background-black {
  background-color: $black;
}

.background-dark-grey {
  background-color: $dark-grey;
}

.display-none {
  display: none !important;
}

