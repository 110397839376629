@import './src/scss/breakpoints';

.logo-default {
  width: 231px;
  height: 49px;

  g {
    path {
      fill: #000000;
    }
  }
}

@media screen and (max-width: $big){
  .logo-default {
    width: 200px;
    height: 42px;
  }
}

@media screen and (max-width: $small){
  .logo-default {
    width: 150px;
    height: 31px;
  }
}