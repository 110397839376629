@import "src/scss/breakpoints";

.btn {
  font-family: "Mark Simpson Proxima Nova", Arial, Tahoma, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  &_green {
    background-color: $green;
    color: $dark-grey;
    border: 1px solid $green;

  }

  &_white {
    color: $white;
    border: 1px solid $white;
    background: transparent;
  }
}

.link {
  font-size: 24px;
  font-family: "Mark Simpson Proxima Nova", Arial, Tahoma, sans-serif;
  font-weight: 700;
  color: $white;
}

.btn-big {
  font-size: 24px;
  height: 70px;
  border-radius: 35px;
  padding: 0 35px;
  min-width: 300px;
  animation: btn-big-appear linear 1.5s;
  white-space: nowrap;
}

@media screen and (max-width: $big) {
  .btn-big {
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    padding: 0 45px;
  }
}

@media screen and (max-width: $big) {
  .btn-big {
    min-width: calc(100% - 10px) ;
  }
}

@media screen and (max-width: $small) {
  .btn-big {
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
    padding: 0 35px;
    min-width: unset;
  }
}