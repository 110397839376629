@import  '../../scss/colours';

.Wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(14px + .5vw);
  padding: 1em;
  border-radius: 8px;

  .Text {
    color: $dark-grey;
    font-size: .7em;
  }

  .Button {
    font-size: 1em;
    background-color: $white;
    border-color: $dark-grey;
    border-radius: 2px;
    color: $dark-grey;
    padding: .5em;
    margin-left: .25em;
  }
}