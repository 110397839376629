@import "src/scss/colours";
@import "src/scss/breakpoints";

.news-card {
  padding: 40px;
  background: rgba(84, 101, 255, 0.2);
  display: flex;
  flex-direction: column;
/*  height: 750px;
  max-height: 750px;
  */
  width: 600px;
  max-width: 600px;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;

  .news-card-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .casino-logo {
      height: 90px;
      width: 90px;
      border-radius: 45px;
      background-color: $white;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .casino-name {
      margin: 0 0 0 30px;
      max-width: 225px;
      font-family: "Mark Simpson Proxima Nova", sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 24px;
    }

    .rating-label {
      position: absolute;
      right: 40px;
    }
  }

  .news-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .news-valid {
      margin: 0 0 30px 0;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }

    .casino-title {
      font-family: "Mark Simpson Proxima Nova", sans-serif;
      font-weight: 900;
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 30px 0;

      a {
        color: $green;
        text-decoration: none;
      }
    }

    .casino-text {
      text-align: center;
      margin: 0 0 40px 0;
      padding: 0 100px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .news-card-footer {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 60px;

    .play-btn {
      text-transform: capitalize;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    .news-card-disclaimer {
      font-size: 16px;
      text-align: center;

      a {
        color: $white;
      }
    }
  }
}

@media screen and (max-width: $big) {
  .news-card {
    height: 600px;
    width: 500px;
    padding: 30px;

    .news-card-header {

      .casino-logo {
        height: 80px;
        width: 80px;
        border-radius: 40px;

      }

      .casino-name {
        margin-right: 20px;
        max-width: 205px;
        font-size: 22px;
        line-height: 22px;
      }

      .rating-label {
        right: 30px;
      }
    }

    .news-card-content {

      .news-valid {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 18px;
      }

      .casino-title {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 25px;
        padding: 0 10px;
      }

      .casino-text {
        text-align: center;
        margin-bottom: 30px;
        padding: 0 50px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .news-card-footer {

      .play-btn {
        min-width: 260px;

        height: 60px;
        border-radius: 30px;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
  }
}

@keyframes rotate-logo {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@media screen and (max-width: $medium) {
  .news-card {
    width: 100%;
    max-width: 520px;
    height: 620px;

    .news-card-header {

      .casino-logo {
        min-width: 80px;
      }

      .casino-name {
        margin: 0;
        padding: 0 10px 0 15px;
      }

      .rating-label {
        position: static;
        margin-left: auto;
      }
    }

    .news-card-content {

      .casino-title {
        margin-bottom: 25px;
        padding: 0 40px;
      }
    }

  }
}

@media screen and (max-width: $small) {

  .news-card {
    width: 100%;
    height: 450px;
    padding: 20px;

    justify-content: space-between;

    .news-card-header {

      .casino-logo {
        height: 50px;
        width: 50px;
        min-width: 50px;
        border-radius: 25px;
      }

      .casino-name {
        margin: 0 0 0 15px;
        max-width: 160px;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .news-card-content {
      justify-content: space-between;

      .news-valid {
        text-align: center;
        font-size: 12px;
        line-height: 15px;
      }

      .casino-title {
        font-size: 30px;
        line-height: 30px;
        margin-top: 15px;
      }

      .casino-text {
        padding: 0 30px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .news-card-footer {
      margin-bottom: 50px;

      .play-btn {
        min-width: 200px;
        height: 50px;
        border-radius: 25px;
        margin-bottom: 50px;

        span {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}