@import './src/scss/colours';
@import './src/scss/breakpoints';

.star-default {
  height: 41px;
  width: 43px;

  g {
    path {
      fill: $black;
    }
  }
}