@import "./src/scss/colours";
@import "./src/scss/breakpoints";

.result-filter-btn {
    display: flex;
    height: 45px;
    border-radius: 24.5px;
    border: 1px solid $dark-grey;
    background-color: $green;
    color: $dark-grey;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    cursor: pointer;

    &.selected {
        background-color: $dark-grey;
        color: $white;
    }

    .result-filter-name {
        font-size: 14px;
        text-transform: lowercase;
    }
}

@media screen and (max-width: $big) {
    .result-filter-btn {
        height: 40px;
        border-radius: 20px;    }
  }