@import "../src/scss/fonts";
@import "../src/scss/colours";
@import "../src/scss/breakpoints";
@import "../src/scss/helpers";
@import "../src/scss/animations";
@import "../src/scss/Components";

$background-color: #000000;
$font-size: 20px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: $white;
  background-color: $background-color;
  font-size: $font-size;
  font-family: 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  &.modal-open {
    overflow-y: hidden;
  }
}
