@import "src/scss/breakpoints";
@import "src/scss/colours";

.review-modal {
  &-container {
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    color: $dark-grey;
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-logo {
    height: 100px;
    width: 100px;
    min-width: 100px;
    border: 1px solid $light-gray;
    border-radius: 50px;
    background-color: $light-gray;
    margin-right: 30px;
  }

  &-title {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    max-width: 500px;
    font-weight: 900;
    font-family: "Mark Simpson Proxima Nova", Arial, Helvetica, sans-serif;
  }

  &-close-btn {
    line-height: 0;
    border: 1px solid $dark-grey;
    height: 45px;
    width: 45px;
    border-radius: 24.5px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 50px;

    svg {
      height: 19px;
      width: 19px;

      g {
        path {
          fill: $dark-grey;
        }
      }

    }
  }

  &-description {
    width: 100%;
    text-align: center;

    p {
      margin: 0 auto;
      font-size: 25px;
      line-height: 35px;
      max-width: 600px;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &-generations-count {
    padding-right: 50px;
    font-size: 16px;
    line-height: 16px;

    a {
      color: $dark-grey;
    }
  }

  &-casino-link {
    height: 70px;
    border-radius: 35px;
    color: $white;
    background-color: $dark-grey;
    border: 1px solid $dark-grey;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 70px;
    cursor: pointer;
    text-decoration: none;
  }
}

@media screen and (max-width: $big) {
  .review-modal {
    &-container {
      padding: 40px;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      top: 40px;
      right: 40px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &-description {
      p {
        font-size: 20px;
        line-height: 28px;
        max-width: 500px;
      }
    }

    &-generations-count {
      padding-right: 40px;
      font-size: 14px;
      line-height: 14px;
    }

    &-casino-link {
      height: 60px;
      border-radius: 30px;
      font-size: 20px;
      line-height: 20px;
      padding: 0 70px;
    }
  }
}

@media screen and (max-width: $medium) {
  .review-modal {

    &-container {
      padding: 0 30px 30px;
    }

    &-logo,
    &-title {
      margin-top: 30px;
    }

    &-header {
      flex-wrap: wrap;
    }

    &-close-btn {
      top: 30px;
      right: 30px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &-description {
      p {
        font-size: 18px;
        line-height: 26px;
        max-width: 500px;
      }
    }

    &-casino-link {
      height: 55px;
      border-radius: 27.5px;
      padding: 0 50px;
    }
  }
}

@media screen and (max-width: $small) {
  .review-modal {
    &-container {
      padding: 25px;
    }

    &-header {
      flex-direction: column;
      align-items: baseline;
    }

    &-logo {
      margin-top: 0;
      height: 80px;
      width: 80px;
      min-width: 80px;
    }

    &-title {
      margin-top: 15px;
      font-size: 30px;
      line-height: 30px;
      max-width: unset;
    }

    &-description {
      p {
        font-size: 16px;
        line-height: 20px;
        max-width: unset;
      }
    }

    &-footer {
      flex-direction: column-reverse;
      align-items: center;
    }

    &-casino-link {
      height: 50px;
      border-radius: 25px;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 15px;
    }

    &-generations-count {
      padding-right: 0;
      font-size: 12px;
      line-height: 12px;
    }
  }
}