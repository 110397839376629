@import "./src/scss/colours";

.modal-overlay {
  position: fixed;
  background-color: rgba(56, 64, 69, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  height: auto;
  z-index: 99999;
  transition: display 2s;
}