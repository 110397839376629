@import './src/scss/colours';
@import './src/scss/breakpoints';

.dice-default {
  height: 41px;
  width: 41px;

  g {
    path {
      fill: #2DD881;
    }
  }
}

@media screen and (max-width: $big) {
  .dice-default {
    height: 32px;
    width: 32px;
  }
}

@media screen and (max-width: $medium) {
  .dice-default {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: $medium) {
  .dice-default {
    height: 27px;
    width: 27px;
  }
}
