@import './src/scss/colours';
@import './src/scss/breakpoints';

.crown-default {
  height: 41px;
  width: 41px;

  &.crown-big {
    height: 50px;
    width: 50px;
  }

  g {
    path {
      fill: #2DD881;
    }
  }
}

@media screen and (max-width: $big) {
  .crown-default {
    height: 32px;
    width: 32px;
  }
}

@media screen and (max-width: $medium) {
  .crown-default {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: $small) {
  .crown-default {
    height: 27px;
    width: 27px;
  }
}