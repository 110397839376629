@import "../../scss/breakpoints";

.slider-container {
  position: relative;
  z-index: 999;

  .slider-items {
    transition: transform 500ms ease-in-out;
  }

  .slider-nav-arrow {
    cursor: pointer;
    position: absolute;
    right: -150px;

    &.arrow-down {
      top: 430px;
    }

    &.arrow-up {
      top: 300px;
    }
  }
}

//animations
.up-arrow-enter {
  opacity: 0;
  transform: translateY(150px);
}

.up-arrow-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms ease;
}

.up-arrow-exit {
  opacity: 1;
  transform: translateY(0);
}

.up-arrow-exit-active {
  opacity: 0;
  transform: translateY(-150px);
  transition: all 300ms ease;
}

.down-arrow-enter {
  opacity: 0;
  transform: translateY(-150px);
}

.down-arrow-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms ease;
}

.down-arrow-exit {
  opacity: 1;
  transform: translateY(0);
}

.down-arrow-exit-active {
  opacity: 0;
  transform: translateY(150px);
  transition: all 300ms ease;
}

@media screen and (max-width: $big) {
  .slider-container {

    .slider-nav-arrow {
      right: -85px;
    }
  }
}


@media screen and (max-width: $medium) {
  .slider-container {

    .slider-nav-arrow {

      &.arrow-down {
        display: none;
      }

      &.arrow-up {
        display: none
      }
    }
  }
}

