@import "./src/scss/breakpoints";
@import "./src/scss/colours";

.rating-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 90px;
  background-color: $blue;
  cursor: pointer;

  .rating-value {
    font-size: 20px;
    line-height: 24px;
    margin: 18px 0 5px 0;
    font-family: 'Mark Simpson Proxima Nova', sans-serif;
  }

  .rating-link {
    display: block;

    span {
      display: block;
    }

    font-size: 12px;
    line-height: 14px;
    color: $white;
    text-align: center;
  }
}

@media screen and (max-width: $big) {
  .rating-label {
    width: 80px;
    height: 80px;
    min-width: 80px;

    .rating-value {
      font-size: 18px;
      line-height: 22px;
      margin-top: 13px;
    }
  }
}

@media screen and (max-width: $small) {
  .rating-label {
    width: 50px;
    height: 50px;
    min-width: 50px;

    .rating-value {
      font-size: 12px;
      line-height: 15px;
      margin-top: 10px;
    }

    .rating-link {
      font-size: 9px;
      line-height: 11px;
    }
  }
}

.rating-label-enter {

  .rating-link, .rating-value {
    display: none;
  }
}

.rating-label-enter-active {

  .rating-link, .rating-value {
    display: none;
  }

  width: 50px;
  height: 50px;
  min-width: 0;
  border-radius: 25px;
  transition: all ease 500ms;
}

.rating-label-exit,
.rating-label-enter-done {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  min-width: 50px;

  .rating-link, .rating-value {
    display: none;
  }
}

.rating-label-exit-active {
  transform: scale(100);
  transition: all ease-in 2000ms;
  z-index: 9999;

  .rating-link, .rating-value {
    display: none;
  }
}

.rating-label-exit-done {
  transform: scale(100);
  z-index: 9999;

  .rating-link, .rating-value {
    display: none;
  }
}
