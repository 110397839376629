.news-header{
    display: flex;
}
.news-container  {
  color: #2dd881;
  text-transform: uppercase;
  font-family: "Mark Simpson Proxima Nova",Tahoma,sans-serif;
  font-weight: 900;
  font-size: 50px;
}
.news-header h1 {
  margin: 0 auto;
}
.news-header a {
  margin-right: 10px;
}
.news-title {
    color: #2DD881;
    text-transform: uppercase;
    font-family: 'Mark Simpson Proxima Nova', Tahoma, sans-serif;
    font-weight: 900;
    font-size: 50px;
  }