@import "src/scss/breakpoints";
@import "src/scss/colours";

.filters-modal-container {
  width: 100%;
  height: 100%;
  background-color: $green;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px;
  color: $dark-grey;
  overflow-y: auto;
}

.filters-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.filters-modal-tittle {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 50px;
  font-family: "Mark Simpson Proxima Nova", Arial, Helvetica, sans-serif;
}

.filters-modal-close-btn {
  line-height: 0;
  border: 1px solid $dark-grey;
  height: 45px;
  width: 45px;
  border-radius: 24.5px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 19px;
    width: 19px;

    g {
      path {
        fill: $dark-grey;
      }
    }

  }
}

.filters-modal-content {
  margin-top: 30px;

  .filters-modal-text {
    font-size: 20px;
    line-height: 30px;
    max-width: 70%;
  }

  .filters-modal-filters-list {
    display: flex;
    margin: 170px auto 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    max-width: 700px;

    .result-filter-btn {
      margin-left: 20px;
      margin-bottom: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.filters-modal-footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .filters-modal-checkbox {
    margin-right: auto;
  }

  .filters-modal-filter-counter {
    margin-right: 30px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters-modal-apply-btn {
    height: 70px;
    border-radius: 35px;
    color: $white;
    background-color: $dark-grey;
    border: 1px solid $dark-grey;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 70px;
    cursor: pointer;
  }
}

@media screen and (max-width: $big) {

  .filters-modal-container {
    padding: 30px;
  }

  .filters-modal-tittle {
    font-size: 40px;
    line-height: 40px;
  }

  .filters-modal-close-btn {
    height: 40px;
    width: 40px;
    border-radius: 20px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .filters-modal-content {
    margin-top: 25px;

    .filters-modal-text {
      font-size: 15px;
      line-height: 23px;
    }

    .filters-modal-filters-list {
      margin-top: 120px;
    }
  }

  .filters-modal-footer {
    .filters-modal-filter-counter {
      margin-right: 20px;
      font-size: 15px;
      line-height: 18px;
    }

    .filters-modal-apply-btn {
      height: 55px;
      border-radius: 27.5px;
      font-size: 20px;
      line-height: 20px;
      padding: 0 55px;
    }
  }
}

@media screen and (max-width: $medium) {
  .filters-modal-content {

    .filters-modal-text {
      max-width: 100%;
      margin: 0;
    }
  }

  .filters-modal-footer {

    .filters-modal-filter-counter {
      min-width: 105px;
    }
  }
}

@media screen and (max-width: $small) {
  .filters-modal-container {
    padding: 25px;
  }

  .filters-modal-tittle {
    font-size: 30px;
    line-height: 30px;
  }

  .filters-modal-close-btn {
    height: 35px;
    width: 35px;
    border-radius: 17.5px;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  .filters-modal-content {
    margin-top: 30px;

    .filters-modal-text {
      font-size: 14px;
      line-height: 18px;
      max-width: 100%;
      margin: 0;
    }
  }

  .filters-modal-footer {

    flex-direction: column;
    align-items: center;
    justify-content: center;

    .filters-modal-checkbox {
      margin-right: unset;
      order: 3;
    }

    .filters-modal-filter-counter {
      margin-right: unset;
      font-size: 14px;
      line-height: 17px;
      order: 1;
      margin-bottom: 15px;
    }

    .filters-modal-apply-btn {
      height: 50px;
      border-radius: 25px;
      font-size: 16px;
      line-height: 16px;
      padding: 0 45px;
      order: 2;
      margin-bottom: 30px;
    }
  }
}