@import './src/scss/colours';
@import './src/scss/breakpoints';

.mark-default {
  height: 61px;
  width: 55px;

  g {
    path {
      fill: $white;
    }
  }
}