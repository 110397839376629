@import './src/scss/colours';
@import './src/scss/breakpoints';

.close-default {
    height: 19px;
    width: 19px;

    g {
      path {
        fill: $white;
      }
    }
}