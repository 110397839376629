@import "../../../../scss/breakpoints";

.winning-container {
  grid-area: winning;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 130px 60px 60px;
  height: 100%;
  box-sizing: border-box;
  background: rgba(84, 101, 255, .07);

  h1, p {
    margin: 0;
    overflow-wrap: break-word;
  }

  .winning-title {
    color: #2DD881;
    text-transform: uppercase;
    font-family: 'Mark Simpson Proxima Nova', Tahoma, sans-serif;
    font-weight: 900;
    font-size: 50px;
  }

  .winning-text {
    line-height: 30px;
  }
}


@media screen and (max-width: $big) {

  .winning-container {
    padding: 50px 130px 50px 50px;


    .winning-title {
      font-size: 40px;
      line-height: 40px;
    }

    .winning-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $medium) {

  .winning-container {
    padding: 40px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .winning-title {
      font-size: 35px;
      margin-bottom: 20px;
    }

    .winning-text {
      font-size: 15px;
      line-height: 21px;
      min-width: 40%;
    }
  }
}

@media screen and (max-width: $small) {

  .winning-container {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .winning-title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .winning-text {
      font-size: 14px;
      line-height: 18px;
      margin-left: 0;
      text-align: center;
    }
  }
}

