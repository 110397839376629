@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/AvenirNextCyr-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/AvenirNextCyr-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Mark Simpson Proxima Nova";
  src: url("../fonts/MarkSimonson-ProximaNovaBlack.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Mark Simpson Proxima Nova";
  src: url("../fonts/MarkSimonson-ProximaNovaBold.otf");
  font-weight: 700;
}
