$background-color-container: #1E1B18;
$background-color: #000000;
@import "../src/scss/breakpoints";

.container {
  max-width: $large;
  min-width: $large;
  margin-left: auto;
  margin-right: auto;
  background-color: $background-color-container;
}

@media screen and (max-width: $big) {
  .container {
    max-width: $big;
    min-width: $big;
    margin: 0;
  }
}

@media screen and (max-width: $medium) {
  .container {
    min-width: 100%;
  }
}

@media screen and (max-width: $small) {
  .container {
    min-width: 100%;
  }

  .hide-on-small {
    display: none !important;
  }
}
