.hide-item-enter {
  display: none !important;
}

.hide-item-enter-active {
  display: none !important;
}

.hide-item-enter-done {
  display: none !important;
}

.hide-item-exit {
  display: none !important;
}

.hide-item-exit-active {
  display: none !important;
}

.hide-item-exit-done {
  display: block;
}

.btn-loader-enter {
  width: 150px;
  min-width: 0 !important;
}

.btn-loader-enter-active {
  width: 70px;
  min-width: 0 !important;
  padding: 0 !important;
  transition: all ease 500ms;
}

.btn-loader-enter-done {
  width: 70px;
  min-width: 0 !important;
  padding: 0 !important;
}

.btn-loader-exit {
  height: 70px;
  width: 70px;
  min-width: 0 !important;
  padding: 0 !important;
  border-radius: 35px;
  z-index: 9999;
}

.btn-loader-exit-active {
  transform: scale(100);
  transition: all ease-in 2000ms;
  min-width: 0 !important;
}

.btn-loader-exit-done {
  z-index: 999;
  position: relative;
  box-shadow: 0 0 0 200vw #2DD881;
  min-width: 300px;
}

.flicker {
  animation: text-flicker 2s linear;
}

.slide-down {
  animation: slide-down 1s ease-in-out;
}

.fade {
  animation: fade-in 1s ease-in;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes btn-big-appear {
  0% {
    padding: 0;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    overflow: hidden;
    color: #2DD881;
    min-width: 0;
  }

  25% {
    height: 70px;
    width: 70px;
    border-radius: 35px;
    color: #2DD881;
    min-width: 0;

  }

  50% {
    padding: 0 35px;
    width: 150px;
    color: #2DD881;
    min-width: 0;

  }

  75% {
    width: 250px;
    min-width: 0;
  }

  100% {
    width: 300px;
    padding: 0 35px;
  }
}

@keyframes slide-down {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes text-flicker {
  0% {
    opacity:0.1;
  }

  2% {
    opacity:1;
  }
  8% {
    opacity:0.1;
  }
  9% {
    opacity:1;
  }
  12% {
    opacity:0.1;
  }
  20% {
    opacity:1;
  }
  25% {
    opacity:0.3;
  }
  30% {
    opacity:1;
  }

  70% {
    opacity:0.7;
  }

  72% {
    opacity:0.2;
  }

  77% {
    opacity:.9;
  }
  100% {
    opacity:.9;
  }
}