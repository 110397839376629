@import "src/scss/breakpoints";
@import "src/scss/colours";

.generate-card {
  padding: 40px;
  background: rgba(84, 101, 255, 0.2);
  display: flex;
  flex-direction: column;
  height: 750px;
  max-height: 750px;
  width: 600px;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  position: relative;

  .btn {
     &.join-btn {
       text-transform: capitalize;
       min-width: unset;
       width: 70%;
       margin-top: auto;
     }
  }

  .icon {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }

  .generate-card-title,
  .join-card-title {
    font-family: "Mark Simpson Proxima Nova", sans-serif;
    font-size: 50px;
    line-height: 50px;
    color: $green;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px 0;
    padding: 0 80px;
  }

  .join-card-title {
    margin-top: .5em;
  }

  .generate-card-text,
  .join-card-text {
    padding: 0 40px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 30px;
  }

  .join-card-input {
    max-width: 450px;
    width: 80%;
    height: 70px;
    border: 1px solid $black;
    border-radius: 35px;
    margin-bottom: 20px;
    padding: 0 45px;
    background-color: $black;
    font-size: 20px;
    line-height: 30px;
    color: $white;

    &:focus {
      outline: none;
    }

    &.error {
      border: 1px solid orangered;
    }
  }

  .join-btn {
  }
  .generate-card-subtext {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 90px;

    a {
      color: $white;
      white-space: nowrap;
    }
  }

  .join-card-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
    align-self: flex-start;

    .terms-link {
      color: $white;
      font-size: 16px;
      line-height: 20px;
      margin-left: 15px;
      border: none;
      text-decoration: underline;
      background-color: transparent;
    }

    figure {
      height: 30px;
      width: 30px;
      border-radius: 6px;
      border: 1px solid $green;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.error {
        border: 1px solid orangered;
      }

      .check-icon {
        height: 15px;
        width: 15px;
      }
    }
  }

  .insta-logo {
    width: 30px;
    height: 30px;

    margin-right: 15px;

    g {
      path {
        fill: $black;
      }
    }
  }

}

@media screen and (max-width: $big) {
  .generate-card {
    height: 600px;
    width: 500px;
    padding: 30px;

    .icon {
      width: 50px;
      height: 50px;
      margin-bottom: 25px;
    }

    .generate-card-title,
    .join-card-title {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 25px;
      padding: 0 75px;
    }

    .generate-card-text,
    .join-card-text {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 24px;
    }

    .join-card-input {
      height: 55px;
      border-radius: 27.5px;
      margin-bottom: 20px;
      padding: 0 30px;
      font-size: 15px;
      line-height: 19px;
    }

    .generate-card-subtext {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      padding: 0 75px;
    }

    .insta-logo {
      width: 25px;
      height: 25px;

      margin-right: 10px;
    }

    .join-card-footer {

      .terms-link {
        color: $white;
        font-size: 16px;
        line-height: 20px;
        margin-left: 15px;

      }

      figure {
        height: 25px;
        width: 25px;

        .check-icon {
          height: 12.5px;
          width: 12.5px;
        }
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .generate-card {
    width: 100%;
    max-width: 520px;
    height: 620px;

    .generate-card-title,
    .join-card-title {
      padding: 0 40px;
    }

    .generate-card-text,
    .join-card-text {
      padding: 0 55px;
    }

    .generate-card-subtext {
      padding: 0 60px;
    }
  }
}

@media screen and (max-width: $small) {
  .generate-card {
    width: 100%;
    height: 450px;
    padding: 20px;

    .icon {
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    .generate-card-title,
    .join-card-title {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
      padding: 0 40px;
    }

    .join-card-title {
      margin-top: 30px;
    }

    .generate-card-text,
    .join-card-text {
      padding: 0 20px;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 30px;
    }

    .join-card-input {
      height: 50px;
      border-radius: 25px;
      margin-bottom: 25px;
      padding: 0 20px;
      font-size: 14px;
      line-height: 18px;
    }

    .join-card-footer {

      .terms-link {
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
      }

      figure {
        height: 21px;
        width: 21px;
        border-radius: 4px;

        .check-icon {
          height: 10px;
          width: 10px;
        }
      }
    }

    .generate-card-subtext {
      font-size: 12px;
      line-height: 16px;
      padding: 0 60px;
    }
  }
}