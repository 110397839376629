@import "./src/scss/colours";
@import "./src/scss/breakpoints";

.news-button {
  font-size: 12px;

  a {
    color: #FFFFFF;
  }
}

@media screen and (max-width: $small) {
  .copy-and-terms {
    font-size: 10px;
    line-height: 13px;
  }
}